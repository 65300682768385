document.addEventListener("readystatechange", (e) => {
	if (document.readyState === "complete") {
		const addCSSclasses = (el, ...cls) =>
			cls.map((cl) => el.classList.add(cl));

		const removeCSSclasses = (el, ...cls) =>
			cls.map((cl) => el.classList.remove(cl));

		// If no-js is on the html tag, replace it
		let noJs = document.querySelector(".no-js");
		if (noJs) {
			addCSSclasses(noJs, "js");
			removeCSSclasses(noJs, "no-js");
		}

		// ======================= Navigation =======================

		const hamburger = document.getElementById("hamburger");
		const navigation = document.getElementsByClassName(
			"mega-menu__container"
		)[0];
		const mobileNavOverlay = document.querySelector(
			".mobile-menu__overlay"
		);

		const menuItems = document.querySelectorAll(".has-mega-menu-children");
		const subMenus = document.querySelectorAll(".mega-menu__sub-menu");
		const subMenuLinks = document.querySelectorAll(".mega-menu__link-text");
		const body = document.body;

		const openMenu = () => {
			if (navigation.dataset.navisopen === "false") {
				openNav();
			} else {
				// resetMenu();
				closeNav();
			}
		};

		const openNav = () => {
			hamburger.classList.toggle("is-active");
			navigation.removeAttribute("hidden");
			navigation.dataset.navisopen = "true";
			// mobileNavOverlay.dataset.navisopen = "true";
			// phoneLink.focus();
			addCSSclasses(body, "overflow-hidden");
			// Listen for focus when nav is open
			// document.addEventListener("focus", closeNavOffFocus, true);
		};

		const closeNav = () => {
			hamburger.classList.toggle("is-active");
			navigation.dataset.navisopen = "false";
			// mobileNavOverlay.dataset.navisopen = "false";
			removeCSSclasses(body, "overflow-hidden");
			// Remove focus listener from document when nav closes
			// document.removeEventListener("focus", closeNavOffFocus, true);
			setTimeout(() => {
				navigation.setAttribute("hidden", "");
			}, 301);
		};

		const closeSubMenu = (subMenu) => {
			// console.log(`close subMenu`, subMenu);
		};

		// If the focus is outside main navigation, close it
		// const closeNavOffFocus = (e) => {
		// 	console.log("closeNavOffFocus");
		// 	let isClickInsideElement = navigation.contains(e.target);

		// 	if (!isClickInsideElement) {
		// 		closeNav();
		// 	}
		// };
		const closeSubMenuOffFocus = (e, subMenu) => {
			let isClickInsideElement = subMenu.contains(e.target);

			if (!isClickInsideElement) {
				closeSubMenu(subMenu);
			}
		};

		const resetMenu = () => {
			menuItems.forEach((item) => {
				item.classList.remove("is-active");
			});

			document.removeEventListener("focus", closeSubMenuOffFocus, true);
		};

		const toggleActiveClass = (e) => {
			e.stopPropagation();
			e.currentTarget.classList.toggle("is-active");
		};

		// add event listeners for submenu opening

		const addSubmenuEvListeners = () => {
			menuItems.forEach((item) => {
				item.addEventListener("click", toggleActiveClass);
			});
		};
		const removeSubmenuEvListeners = () => {
			menuItems.forEach((item) => {
				item.removeEventListener("click", toggleActiveClass);
			});
		};

		subMenuLinks.forEach((link) => {
			link.addEventListener("click", (e) => {
				e.stopPropagation();
			});
		});

		// This is where it starts

		// initial set nav as hidden for tabbing
		if (window.outerWidth < 1023) {
			navigation.setAttribute("hidden", "");
			addSubmenuEvListeners();
		}

		// set nav as hidden for tabbing on window resize
		window.addEventListener("resize", () => {
			resetMenu();
			// navigation.dataset.navisopen = "false";
			// mobileNavOverlay.dataset.navisopen = "false";
			// removeCSSclasses(body, "overflow-hidden");
			// document.removeEventListener("focus", closeNavOffFocus, true);

			if (window.outerWidth < 1023) {
				addSubmenuEvListeners();
				navigation.setAttribute("hidden", "");
			} else {
				navigation.removeAttribute("hidden");
				removeSubmenuEvListeners();
			}
		});

		window.onfocus = function () {
			if (window.outerWidth < 1023) {
				addSubmenuEvListeners();
				navigation.setAttribute("hidden", "");
			} else {
				navigation.removeAttribute("hidden");
				removeSubmenuEvListeners();
			}
		};

		hamburger.addEventListener("click", openMenu, false);
		// hamburger.addEventListener("focus", openMenu);

		// close nav and submenu when clicked on overlay
		// mobileNavOverlay.addEventListener("click", () => {
		// 	resetMenu();
		// 	closeNav();
		// });

		// hide submenu when tabbed off of it

		subMenus.forEach((subMenu) => {
			subMenu.addEventListener(
				"blur",
				(e) => closeSubMenuOffFocus(e, subMenu),
				true
			);
		});

		// ======================= Footer accordion =======================

		const footerMenuToggles = document.querySelectorAll(
			".footer-menu-toggle"
		);

		footerMenuToggles.forEach((toggle) => {
			toggle.addEventListener("click", (e) => {
				e.stopPropagation();
				if (toggle.dataset.isopen === "false") {
					toggle.dataset.isopen = "true";
				} else {
					toggle.dataset.isopen = "false";
				}
			});
		});

		// ======================= Promo Bar =======================

		const promoBar = document.getElementById("promo-bar");
		const promoBarCLoseBtn = document.getElementById("promo-bar-close-btn");

		if (promoBar && promoBarCLoseBtn) {
			function setCookie(name, value, days) {
				var expires = "";
				if (days) {
					var date = new Date();
					date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
					expires = "; expires=" + date.toUTCString();
				}
				document.cookie =
					name + "=" + (value || "") + expires + "; path=/";
			}

			function getCookie(name) {
				var nameEQ = name + "=";
				var ca = document.cookie.split(";");
				for (var i = 0; i < ca.length; i++) {
					var c = ca[i];
					while (c.charAt(0) == " ") c = c.substring(1, c.length);
					if (c.indexOf(nameEQ) == 0)
						return c.substring(nameEQ.length, c.length);
				}
				return null;
			}

			// promoBar.style.display = "block";

			if (getCookie("displayPromoBar") === "0") {
				// if cookie is not set, show promo bar
				promoBar.style.display = "none";
			}

			promoBarCLoseBtn.addEventListener("click", () => {
				promoBar.style.display = "none";

				setCookie("displayPromoBar", "0", 5000);
			});
		}

		// ======================= WordPress Admin Bar =======================

		/**
		 * Hide that pesky wordpress admin bar
		 */
		const admin = document.querySelector(".admin-bar");
		const adminBar = document.querySelector("#wpadminbar");

		if (admin && adminBar) {
			addCSSclasses(adminBar, "up");

			window.onmousemove = (e) => {
				// height from the top in px that we want to open the admin bar if we enter the area
				const openBarRegion = 30;
				if (e.clientY <= openBarRegion) {
					removeCSSclasses(adminBar, "up");
				} else {
					if (!adminBar.classList.contains("up")) {
						addCSSclasses(adminBar, "up");
					}
				}
			};
		}

		// ======================= Homepage Slider =======================

		const sliderSection = document.getElementById("slider-section");

		if (sliderSection) {
			const articles = document.querySelectorAll(
				".homepage-slider__article"
			);

			if (articles.length > 0) {
				let slides = [];
				articles.forEach((article, index) => {
					slides.push(
						document.getElementById(
							"homepage-slider__article-image-" + (index + 1)
						)
					);
				});

				document.addEventListener("scroll", function () {
					const sliderSectionRect =
						sliderSection.getBoundingClientRect();
					const sectionTop = sliderSectionRect.top;

					if (sectionTop < 0) {
						articles.forEach((article, index) => {
							const rect = article.getBoundingClientRect();
							const articleTop = rect.top;
							if (articleTop < window.innerHeight / 2) {
								slides[index].classList.add("active");
							} else {
								slides[index].classList.remove("active");
							}
						});
					}
				});
			}
		}

		// =======================  Back to Top Button =======================

		let backToTopButton = document.getElementById("backToTopBtn");

		// When the user scrolls down 20px from the top of the document, show the button
		window.onscroll = function () {
			scrollFunction();
		};

		function scrollFunction() {
			if (
				document.body.scrollTop > 820 ||
				document.documentElement.scrollTop > 820
			) {
				backToTopButton.style.display = "flex";
			} else {
				backToTopButton.style.display = "none";
			}
		}

		// When the user clicks on the button, scroll to the top of the document
		function topFunction() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}

		backToTopButton.addEventListener("click", topFunction);

		// ======================= End Back to Top Button =======================
		const subscribeBtn = document.querySelector(
			"#subscribebtn .f0-button__link"
		);
		const subscribePopup = document.querySelector("#subscribepopup");
		const subscribeCloseBtn = document.querySelector(
			".subscribe__popup-close"
		);

		if (subscribeBtn && subscribePopup) {
			subscribeBtn.addEventListener("click", (e) => {
				e.preventDefault();
				console.log("Subscribe button clicked");
				subscribePopup.style.display = "block";

				window.onclick = function (event) {
					if (event.target == subscribePopup) {
						subscribePopup.style.display = "none";
					}
				};
			});

			subscribeCloseBtn.addEventListener("click", (e) => {
				e.preventDefault();
				subscribePopup.style.display = "none";
			});
		}
	}

	// Code above this brace
});
